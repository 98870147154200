.listContainer {
  position: relative;
}

.input {
  background-color: var(--second-button-color);
  display: flex;
  border: none;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0 0 0 1rem;
  cursor: var(--cursor-pointer);
}

.input:hover {
  background-color: var(--second-button-color-hover);
}
.input:disabled {
  background-color: var(--second-button-color-disabled);
}

.list {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--second-button-color);
  border-radius: 0 0 0.5rem 0.5rem;
  min-height: 10rem;
  max-height: 20rem;
  overflow-y: auto;
  margin: 0.05rem 0 0 0;
  z-index: 5;
}

.list::-webkit-scrollbar {
  width: 0.4rem;
  background-color: transparent;
}

.option {
  width: 85%;
  min-height: 2.5rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 0.5rem;
  font-size: var(--font-size)-0.1rem;
  cursor: var(--cursor-pointer);
}

.option:hover {
  background-color: var(--second-button-color-hover);
}

.selectedOption {
  background-color: var(--second-button-color-actived);
}

.selectedOption:hover {
  background-color: #cdcdcd;
}

.selected {
  display: flex;
  background-color: var(--second-button-color);
  border: none;
  margin: 0.75rem 0 0 0;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 10px -10px var(--shadow-color);
  padding: 1rem;
  min-height: 3rem;
  max-height: 30rem;
  overflow-y: auto;
  z-index: 10;
}

.item {
  padding: 0 0.25rem 0;
  margin: 0 0.125rem;
  display: flex;
  align-items: center;
}

.remove {
  border: none;
  background-color: var(--second-button-color);
  align-items: end;
  cursor: var(--cursor-pointer);
}

@media (max-width: 750px) {
  .selected,
  .input,
  .list,
  .option {
    font-size: var(--font-size)-0.3rem;
  }
  .selected {
    width: 9rem;
  }
  .input {
    width: 10rem;
  }
  .list {
    width: 11rem;
  }
}

@media (min-width: 751px) {
  .selected,
  .input,
  .list,
  .option {
    font-size: var(--font-size)-0.2rem;
  }
  .selected {
    width: 9rem;
  }
  .input {
    width: 10rem;
  }
  .list {
    width: 11rem;
  }
}

@media (min-width: 1024px) {
  .selected,
  .input,
  .list,
  .option {
    font-size: var(--font-size);
  }
  .selected {
    width: 11rem;
  }
  .input {
    width: 12rem;
  }
  .list {
    width: 13rem;
  }
}
