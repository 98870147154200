@import "../../colors.css";

.header {
  display: flex;
  align-items: center;
  height: 5rem;
  width: 100vw;
  padding: 0 1rem;
  background-color: #2f5141;
  box-shadow: 0 10px 10px -10px var(--shadow-color);
  position: relative;
}

.greenContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #9fc54d;
  z-index: 1;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.logo {
  height: 9.1rem;
  width: 28rem;
  cursor: var(--cursor-pointer);
  margin: 0 0 0 1rem;
}

.navigation {
  display: flex;
  align-items: center;
  z-index: 1;
}

.route {
  text-decoration: none;
  margin: 0 2.5rem 0 0;
  color: #e0e0e0;
  position: relative;
  transition: color 0.3s ease;
  font-weight: bold;
  cursor: var(--cursor-pointer);
}

.route::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: var(--first-text-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease;
}

.route:hover {
  color: #ffff;
}

.route:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.linkText {
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  font-size: var(--font-size) + 0.2rem;
}

.linkText::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--first-text-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease;
}

.modal {
  position: absolute;
  top: calc(100% + 10px);
  right: 3rem;
  width: 15rem;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
}

.modal ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal ul li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.modal ul li:last-child {
  border-bottom: none;
}

.modalItem {
  width: 100%;
  color: var(--light-shadow-color);
}

@media (max-width: 750px) {
  .logo {
    height: 6rem;
    width: 18.4rem;
  }
  .modalItem {
    font-size: var(--font-size)-0.1rem;
  }
}

@media (min-width: 751px) {
  .modalItem {
    font-size: var(--font-size) + 0.2rem;
  }
}

@media (max-width: 940px) {
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .navigation {
    display: none;
  }

  .responsiveButton {
    height: 4rem;
    width: 4rem;
    background-color: #2f5141;
    color: white;
    border: none;
    margin: 0 1rem 0 0;
  }
}

@media (min-width: 1361px) {
  .logo {
    height: 9.1rem;
    width: 28rem;
  }
}
