.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

.form {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: var(--font-size) + 0.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.button {
  height: 3rem;
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--first-button-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: var(--font-size);
  margin-top: 1rem;
  color: #fff;
  cursor: var(--cursor-pointer);
  transition: background-color 0.3s ease;
  color: var(--first-text-color);
}

.button:hover {
  background-color: var(--first-button-color-hover);
}

.button:disabled {
  background-color: var(--first-button-color-disabled);
  cursor: var(--cursor-not-allowed);
  color: var(--first-text-color-disabled);
}

.input {
  background-color: #fff;
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0 1rem;
  font-size: var(--font-size);
  margin-top: 1rem;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.input:hover {
  background-color: #f0f0f0;
}

.input:disabled {
  background-color: #f0f0f0;
  cursor: var(--cursor-not-allowed);
}
