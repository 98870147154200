@import "../../colors.css";

.container {
  width: 80vw;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 0;
}

.calendar {
  position: absolute;
  left: -27.5rem;
  top: -5rem;
  z-index: 0 !important;
}

.renderFooter {
  display: flex;
  justify-content: center;
}

.clearButton {
  border: #0fbcf9;
  color: #000;
  border-radius: 0.5rem;
  width: 5rem;
  height: 2rem;
  cursor: var(--cursor-pointer);
}

.input,
.selected {
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.keyword {
  margin: 0 0 0 -5rem;
}

.type {
  display: flex;
  width: 7rem;
  margin: 0 0 0 -5rem;
}

.code {
  background-color: var(--second-button-color);
  margin: 0 0.5rem 0.5rem 0;
  border: none;
  width: 10rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0 0 0 1rem;
  font-size: var(--font-size);
}

.info {
  display: flex;
  align-items: center;
}

.button {
  height: 2.5rem;
  width: 90%;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--first-button-color);
  box-shadow: 0 10px 10px -10px var(--shadow-color);
  font-size: var(--font-size);
}

.code:hover,
.clearButton:hover {
  background-color: var(--second-button-color-hover);
}

.button:hover {
  background-color: var(--first-button-color-hover);
}

.button:disabled {
  background-color: var(--first-button-color-disabled);
}

.tooltip {
  position: absolute;
  background-color: var(--second-button-color);
  padding: 0.5rem 1rem;
  border-radius: 0.35rem;
  z-index: 1000;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin: 1.5rem 0 0 0;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeOut {
  animation-name: fadeOut;
}

.question {
  width: 10rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 750px) {
  .align {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .calendarContainer {
    width: auto;
    margin: 0 0 0.5rem;
  }
  .calendar {
    position: relative;
    top: 0;
    left: 0;
  }

  .lastColumn,
  .firstColumn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .code,
  .keyword,
  .type {
    width: 10rem;
    margin: 0.5rem 0;
  }

  .keyword,
  .type {
    margin-left: 1rem;
  }
  .date,
  .input,
  .list,
  .option,
  .info,
  .checkbox {
    width: 70%;
    margin: 1rem 0.5rem;
    padding: 0;
  }
  .info {
    flex-direction: column;
  }
  .code {
    width: 8rem;
    font-size: var(--font-size)-0.3rem;
  }

  .question,
  .yes {
    font-size: var(--font-size)-0.2rem;
  }

  .button {
    padding: 0 0.5rem;
    margin: 0 0 0 0.5rem;
    font-size: var(--font-size)-0.3rem;
  }
}

@media (min-width: 751px) {
  .align {
    display: flex;
    flex-direction: row;
  }
  .date,
  .input,
  .selected,
  .list,
  .option,
  .info,
  .checkbox {
    width: 70%;
    margin: 0;
    padding: 0;
  }
  .align {
    display: flex;
    flex-direction: column;
  }
  .calendarContainer {
    width: auto;
    margin: 0 0 0.5rem;
  }
  .calendar {
    position: relative;
    top: 0;
    left: 0;
  }
  .firstColumn,
  .lastColumn {
    display: flex;
  }
  .lastColumn {
    align-items: center;
  }

  .type,
  .keyword,
  .code {
    margin: 0.5rem 0;
  }

  .info {
    flex-direction: column;
  }
  .button {
    padding: 0.5rem 0;
  }
  .code {
    width: 10rem;
    font-size: var(--font-size)-0.2rem;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 70vw;
  }
  .align {
    display: flex;
    flex-direction: row;
  }
  .firstColumn {
    display: flex;
    flex-direction: column;
    margin: 0 1rem 0 1rem;
  }
  .lastColumn {
    display: flex;
    flex-direction: column;
  }
  .type {
    margin-top: 1.5rem;
  }
  .code {
    width: 12rem;
    font-size: var(--font-size);
  }
}
@media (min-width: 1600px) {
  .container {
    height: 14rem;
  }
  .calendarContainer {
    left: -23rem;
    top: -4rem;
  }
  .firstColumn,
  .lastColumn {
    position: relative;
  }
  .firstColumn {
    left: -17.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .lastColumn {
    max-width: 15rem;
  }
  .button {
    padding: 0 1rem;
    margin: 0 0 0 0.5rem;
  }

  .type {
    margin-top: 0.5rem;
    margin: 0.5rem 0 0 3rem;
  }
  .lastColumn {
    left: -7.5rem;
  }
}
