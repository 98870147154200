.container {
}

.a11y {
  display: flex;
  position: fixed;
  top: 50%;
  right: 1rem;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  z-index: 1000;
  gap: 1rem;
}

.button {
  background-color: var(--first-button-color);
  color: var(--first-text-color);
  border: none;
  border-radius: 4px;
  cursor: var(--cursor-pointer);
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: var(--first-button-color-hover);
}

@media screen and (max-width: 500px) {
  .controls,
  .a11y {
    display: block;
    position: absolute;
    top: 5rem;
    right: 0.5rem;
  }

  .option {
    margin: 0.5rem 0 0 0.5rem;
  }
  
  .button {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 501px) {
  .button {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .button {
    width: 3rem;
    height: 3rem;
  }
}
