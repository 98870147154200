.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem auto 0;
  width: 100vw;
}

.regulation {
  width: 50vw;
  max-width: 800px;
  text-align: center;
  line-height: 1.75rem;
  color: #505050;
  margin: 1rem auto;
}

.table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50vw;
  max-width: 800px;
  margin: 0 auto;
  margin: 0 0 5rem 0;
}

@media (max-width: 750px) {
  .regulation {
    min-width: 75%;
    font-size: var(--font-size)-0.2rem;
    line-height: 1.25rem;
  }

  .table {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 751px) {
  .regulation {
    width: 90%;
    font-size: var(--font-size) + 0.2rem;
    line-height: 1.75rem;
  }

  .table {
    width: 80%;
    height: 80%;
  }
}

@media (min-width: 1024px) {
  .regulation {
    font-size: var(--font-size) + 0.1rem;
  }

  .table {
    width: 100%;
    height: 100%;
  }
}
