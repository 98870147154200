@import "../../colors.css";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.loginForm {
  width: 300px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.formGroup {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  background-color: var(--second-button-color);
  display: flex;
  border: none;
  width: 17.5rem;
  height: 3rem;
  border-radius: 0.5rem;
  padding: 0 0 0 1rem;
  font-size: var(--font-size);
}

.input:hover,
.date:hover {
  background-color: var(--second-button-color-hover);
}
.input:disabled,
.date:disabled {
  background-color: var(--second-button-color-disabled);
}

.forgotPassword {
  display: block;
  text-align: center;
  margin-bottom: 20px;
  color: #007bff;
  text-decoration: none;
}
.formButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  height: 2.5rem;
  width: 7.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--first-button-color);
  box-shadow: 0 10px 10px -10px var(--shadow-color);
  font-size: var(--font-size);
}

.button:hover {
  background-color: var(--first-button-color-hover);
}

.button:disabled {
  background-color: var(--first-button-color-disabled);
}
